import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo-black.png';
import './dashboard';
import '../App.css';
import '../index.css';

export default function Login()
{
   const [userName, setUserName] = useState('');
   const [userIdCode, setUserIdCode] = useState('');

   const navigate = useNavigate();
   
   const getIsValidCredentials = (userName) =>
   {
       switch (userName.toLocaleLowerCase())
       {
            case "anna":
               if ("OG0001" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "claire":
               if ("OG0002" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
           case "emma":
              if ("OG0003" === userIdCode)
              {
                  return true;
              }
              else
              {
                  return false;
              }
            case "wiktor":
               if ("OG0004" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "jacob":
               if ("OG0005" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "rebecca":
               if ("OG0006" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "francisco":
               if ("OG0007" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
           case "gabriel":
               if ("OG0008" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "hannah":
               if ("OG0009" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "alex":
               if ("OG0010" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "hugh":
               if ("OG0011" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "connor":
               if ("OG0012" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "gia":
               if ("OG0013" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "elizabeth":
               if ("OG0014" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "grace":
               if ("OG0024" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "olivia":
               if ("OG0023" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "kevin":
               if ("OG0015" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "ben":
               if ("OG0016" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "ethan":
               if ("OG0017" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "channie":
               if ("OG0018" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "finn":
               if ("OG0019" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "sidney":
               if ("OG0020" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "alice":
               if ("OG0021" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "jack":
               if ("OG0022" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "haley":
               if ("OG0025" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "tyler":
               if ("OG0026" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "sylva":
               if ("OG0027" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "ojas":
               if ("OG0028" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "maya":
               if ("OG0029" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            case "arthur":
               if ("OG0030" === userIdCode)
               {
                   return true;
               }
               else
               {
                   return false;
               }
            default:
               return false;
       }
   };
    
   const onSubmit = (ev) =>
   {
       ev.preventDefault();
       if (getIsValidCredentials(userName))
       {
           const params = new URLSearchParams();
           params.append('userIdCode', userIdCode);
           params.append('userName', userName);
           
           axios({
              method: 'post',
              url: 'https://macro-landing-442200-m2.wl.r.appspot.com/athlete/',
              data: params
           }).then(res => {
               console.log("Logged in")
               navigate('/dashboard');
           }).catch((err) => {
               console.log(err)
           });
       }
       else
       {
           setUserName("");
           setUserIdCode("");
           alert('The first name and user Id combination you entered does not match any records in our system. Please Enter a valid first name and user Id combination.')
       }
   };

   return (
    <form onSubmit={onSubmit} className={'mainContainer'}>
          <div className={'titleContainer'}>
            <div>OptiGenix</div>
            <img src={logo} className="logo-img-big" alt="logo-img"/>
          </div>
          <br />
           <div className={'inputContainer'}>
             <input
               value={userName}
               placeholder="Enter your first name only"
               onChange={(ev) => setUserName(ev.target.value)}
               className={'inputBox'}
             />
           </div>
          <br />
          <div className={'inputContainer'}>
            <input
              value={userIdCode}
              placeholder="Enter your user identification code"
               onChange={(ev) => setUserIdCode(ev.target.value)}
              className={'inputBox'}
            />
          </div>
          <br />
          <div className={'inputContainer'}>
            <button type="submit" className={'loginBtn'}>Login</button>
          </div>
      </form>
  );
};

