import React, { useState } from 'react';
import axios from 'axios';
import '../style/widgets/bloodWidget.css';
import lowDial from '../assets/low-dial.png';
import normalDial from '../assets/normal-dial.png';
import highDial from '../assets/high-dial.png';
import logo from '../assets/logo-white.png';

export default function BloodInsightsWidget()
{

    const [biomarkerInfo, setBiomarkerInfo] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    /*call on button click*/
    async function fetchBioData(ev)
    {
        ev.target.disabled = true;
        try
        {
            let res = await axios.get('https://macro-landing-442200-m2.wl.r.appspot.com/biomarker/');
            setBiomarkerInfo(res.data);
        }
        catch (err)
        {
            console.log(err);
        }
    }

    /*useEffect(() =>
    {
        fetchBioData();
    }, [])*/

    const nextInsight = () =>
    {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % genericBloodInfo.length);
    };

    const prevInsight = () =>
    {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + genericBloodInfo.length) % genericBloodInfo.length);
    };

    const getLevelUnits = (biomarkerName) =>
    {
        switch(biomarkerName)
        {
          case 'Hemoglobin':
          case 'Protein, Total':
          case 'Albumin':
          case 'Globulin (calc)':
                return " g/dL"
          case 'Glucose':
          case 'Calcium':
          case 'Bilirubin, Total':
          case 'BUN (Blood Urea Nitrogen)':
          case 'Creatinine, Serum':
          case 'Cholesterol, Total':
          case 'Triglycerides':
          case 'HDL Cholesterol':
          case 'LDL Cholesterol (calc.)':
          case 'VLDL Cholesterol (calc.)':
          case "Non-HDL Cholesterol (calc.)":
          case 'Phosphate (as Phosphorus)':
          case 'Magnesium, Serum':
                return " mg/dL"
          case 'Vitamin B12': return " pg/mL"
          case 'Cortisol': return " ug/dL"
          case 'Hematocrit': return "%"
          case 'Sodium':
          case 'Potassium':
          case 'Chloride':
          case 'Carbon Dioxide (CO2)':
                return " mmol/L"
          case 'Homocysteine': return " umol/L"
          case 'ALT (Alanine Aminotransferase)': return " U/L"
          case 'ALP (Alkaline Phosphatase)':
          case 'AST (Aspartate Aminotransferase)':
          case 'Creatine Kinase (CK/CPK), Total':
                return " IU/L"
          case 'Ferritin':
          case 'Folate, Serum':
          case 'Vitamin D, 25-OH':
                return " ng/mL"
          default: return ""
        }
    };

    const genericBloodInfo =
    [
        {
             name: "Hemoglobin",
             description: "Hemoglobin is an iron-rich protein in red blood cells responsible for transporting oxygen to tissues throughout the body. Healthy hemoglobin levels are crucial for maintaining optimal oxygen delivery, energy production, and muscle recovery, which are essential for daily activities and athletic performance. Maintaining hemoglobin within the normal range supports endurance, stamina, and overall physical performance.",
             maleRange: "13.5-16.9 g/dL",
             femaleRange: "11.5-15.0 g/dL"
         },
         {
             name: "Hematocrit",
             description: "While hematocrit is essential for iron storage and transport, excessive levels can lead to adverse health effects. Symptoms of excess hematocrit may include headaches, feeling light-headed or dizzy, shortness of breath, and a general sense of weakness or fatigue. Low Hematocrit levels can lead to symptoms such as pale complexion, weakness, fatigue, trouble breathing, irregular heartbeat, and cold hands or feet.",
             maleRange: "39.5-50.0%",
             femaleRange: "34.0-44.0%"
         },
         {
             name: "Glucose",
             description: "Glucose provides immediate energy for physical activity and endurance during exercise.",
             maleRange: "65-100 mg/dL",
             femaleRange: "65-100 mg/dL"
         },
         {
             name: "Sodium",
             description: "Sodium helps maintain fluid balance, supports hydration, and prevents muscle cramps during exercise.",
             maleRange: "135-145 mmol/L",
             femaleRange: "135-145 mmol/L"
         },
         {
             name: "Potassium",
             description: "Potassium regulates muscle contraction and nerve signaling, essential for strength and preventing fatigue.",
             maleRange: "3.4-5.3 mmol/L",
             femaleRange: "3.4-5.3 mmol/L"
         },
         {
             name: "Chloride",
             description: "Chloride works with sodium to maintain hydration and fluid balance, supporting physical stamina.",
             maleRange: "98-107 mmol/L",
             femaleRange: "98-107 mmol/L"
         },
         {
             name: "Carbon Dioxide (CO2)",
             description: "Carbon Dioxide reflects the body’s ability to balance pH, critical for muscle performance and recovery.",
             maleRange: "22-29 mmol/L",
             femaleRange: "22-29 mmol/L"
         },
         {
             name: "Calcium",
             description: "Calcium supports muscle contraction, bone strength, and nerve function, all vital for athletic performance.",
             maleRange: "8.4-10.7 mg/dL",
             femaleRange: "8.4-10.7 mg/dL"
         },
         {
             name: "Protein, Total",
             description: "Protein measures the body’s protein stores, essential for muscle repair and growth.",
             maleRange: "6.0-8.2 g/dL",
             femaleRange: "6.0-8.2 g/dL"
         },
         {
             name: "Albumin",
             description: "Albumin is a protein made in the liver that helps keep fluid from leaking out of blood vessels and transports hormones, medicines, vitamins, and other essential substances throughout the body.",
             maleRange: "3.2-5.5 g/dL",
             femaleRange: "3.2-5.5 g/dL"
         },
         {
            name: "Globulin (calc)",
            description: "Globulins are a group of proteins in blood made by the liver and immune system important in kidney function, blood clotting, and immune function.",
            maleRange: "1.9-3.7 g/dL",
            femaleRange: "1.9-3.7 g/dL"
         },
         {
             name: 'Albumin: Globulin Ratio',
             description: "The Albumin Globulin Ratio reflects protein balance, supporting recovery and overall energy levels.",
             maleRange: "1.2-2.5",
             femaleRange: "1.2-2.5"
         },
         {
             name: "Bilirubin, Total",
             description: "Bilirubin helps measure red blood cell turnover, which supports oxygen delivery to muscles.",
             maleRange: "0.3-1.1 mg/dL",
             femaleRange: "0.3-1.1 mg/dL"
         },
         {
             name: "ALP (Alkaline Phosphatase)",
             description: "Alkaline Phosphatase supports bone health, which is critical for athletes undergoing physical stress.",
             maleRange: "42-128 IU/L",
             femaleRange: "42-128 IU/L"
         },
         {
             name: "AST (Aspartate Aminotransferase)",
             description: "AST is an important enzyme that plays a crucial role in liver health. Elevated levels can indicate liver or muscle damage, while normal levels support overall metabolic function.",
             maleRange: "10-42 IU/L",
             femaleRange: "10-42 IU/L"
         },
         {
             name: 'ALT (Alanine Aminotransferase)',
             description: "ALT is an enzyme primarily found in your liver, though smaller amounts are present in your muscles, kidneys, and other organs. Your body uses ALT to break down food into energy, and normally, ALT levels in the blood remain low. Elevated levels are typically a sign of liver damage, as the liver releases more ALT into the blood when it's injured.",
             maleRange: "7-50 U/L",
             femaleRange: "7-50 U/L"
         },
         {
             name: "BUN (Blood Urea Nitrogen)",
             description: "BUN measures urea nitrogen in the blood, indicating kidney function and protein metabolism.",
             maleRange: "7-18 mg/dL",
             femaleRange: "7-18 mg/dL"
         },
         {
             name: "Creatinine, Serum",
             description: "Creatinine reflects muscle metabolism, helping assess energy use and muscle function.",
             maleRange: "0.60-1.30 mg/dL",
             femaleRange: "0.60-1.30 mg/dL"
         },
         {
            name: "BUN: Creatinine Ratio",
            description: "This ratio helps evaluate kidney function and hydration status. Low levels may indicate malnutrition or liver issues, while high levels can be caused by dehydration or high protein intake.",
            maleRange: "6.0-22.0",
            femaleRange: "6.0-22.0"
         },
         {
            name: "Estimated GFR (calc)",
            description: "The estimated GFR measures kidney efficiency in filtering waste, essential for overall health and recovery after physical exertion.",
            maleRange: ">/= 90 mL/min/1.73m2",
            femaleRange: ">/= 90 mL/min/1.73m2"
         },
         {
             name: "Cholesterol, Total",
             description: "Total cholesterol is vital for hormone production and energy regulation during athletic performance.",
             maleRange: "0-199 mg/dL",
             femaleRange: "0-199 mg/dL"
         },
         {
             name: "Triglycerides",
             description: "Triglycerides serve as an energy reserve, supporting prolonged physical activity and endurance.",
             maleRange: "0-149 mg/dL",
             femaleRange: "0-149 mg/dL"
         },
         {
             name: "HDL Cholesterol",
             description: "HDL cholesterol supports cardiovascular health and assists in energy transport during physical activity.",
             maleRange: ">40 mg/dL",
             femaleRange: ">40 mg/dL"
         },
         {
             name: "LDL Cholesterol (calc.)",
             description: "LDL cholesterol aids in hormone production, crucial for muscle recovery and sustained performance.",
             maleRange: "<100 mg/dL",
             femaleRange: "<100 mg/dL"
         },
         {
             name: "VLDL Cholesterol (calc.)",
             description: "VLDL cholesterol transports triglycerides, supplying energy for endurance and long-duration activities.",
             maleRange: "<30 mg/dL",
             femaleRange: "<30 mg/dL"
         },
         {
             name: "Non-HDL Cholesterol (calc.)",
             description: "Non-HDL cholesterol reflects overall cardiovascular health, influencing energy efficiency and endurance.",
             maleRange: "0-159 mg/dL",
             femaleRange: "0-159 mg/dL"
         },
         {
             name: "Cholesterol: HDL Ratio",
             description: "The ratio indicates how efficiently the body utilizes cholesterol for energy and recovery.",
             maleRange: "<5.0",
             femaleRange: "<5.0"
         },
         {
             name: "Homocysteine",
             description: "Homocysteine influences liver health, protein synthesis, and inflammation regulation. Elevated levels can indicate cardiovascular risks.",
             maleRange: "5.0-15.0 umol/L",
             femaleRange: "5.0-15.0 umol/L"
         },
         {
             name: "Creatine Kinase (CK/CPK), Total",
             description: "Creatine kinase is essential for heart and muscle function. Elevated levels can reflect intense exercise or muscle injury.",
             maleRange: "25-174 IU/L",
             femaleRange: "25-174 IU/L"
         },
         {
             name: "Phosphate (as Phosphorus)",
             description: "Phosphate supports bone strength and muscle function, preventing weakness and promoting endurance.",
             maleRange: "2.5-4.6 mg/dL",
             femaleRange: "2.5-4.6 mg/dL"
         },
         {
            name: "Magnesium, Serum",
            description: "Magnesium is key for optimal muscle function, energy production, and electrolyte balance, all of which are essential for peak athletic performance.",
            maleRange: "1.80-2.80 mg/dL",
            femaleRange: "1.80-2.80 mg/dL"
         },
         {
             name: "Cortisol",
             description: "Cortisol regulates stress response, energy production, and aids in recovery after exercise.",
             maleRange: "6.7-22.6 ug/dL (AM), <10 ug/dL (PM)",
             femaleRange: "6.7-22.6 ug/dL (AM), <10 ug/dL (PM)"
         },
         {
             name: "Ferritin",
             description: "Ferritin stores iron, which is crucial for energy metabolism, oxygen transport, and athletic endurance.",
             maleRange: "23.9-336.2 ng/mL",
             femaleRange: "11.0-306.8 ng/mL"
         },
         {
             name: "Folate, Serum",
             description: "Folate supports red blood cell formation, muscle repair, and overall cell health, aiding performance and recovery.",
             maleRange: ">5.9 ng/mL",
             femaleRange: ">5.9 ng/mL"
         },
         {
             name: "Vitamin B12",
             description: "Vitamin B12 is essential for red blood cell production, nerve health, and energy metabolism.",
             maleRange: "180-914 pg/mL",
             femaleRange: "180-914 pg/mL"
         },
         {
             name: "Vitamin D, 25-OH",
             description: "Vitamin D supports bone health, muscle function, and calcium absorption, essential for performance and injury prevention.",
             maleRange: "30.0-100.0 ng/mL",
             femaleRange: "30.0-100.0 ng/mL"
         }
    ];

    //need to add logic for the one sub-low image as well
    const getGaugeImg = (resultInd) =>
    {
        switch(resultInd)
        {
          case 'L': return lowDial
          case 'N': return normalDial
          case 'H': return highDial
          default: return logo
        }
    };

    const getLevelStyleClass = (resultInd) =>
    {
        switch(resultInd)
        {
          case 'L': return "blood-level-low"
          case 'N': return "blood-level-normal"
          case 'H': return "blood-level-high"
          default: return ""
        }
    };

    const getLevel = (resultInd) =>
    {
        switch(resultInd)
        {
          case 'L': return 'Low'
          case 'N': return 'Normal'
          case 'H': return 'High'
          default: return ''
        }
    };

    const getLevelKeyword = (biomarkerListLength) =>
    {
        if (biomarkerListLength > 0)
        {
            return "Level: ";
        }
        else
        {
            return "";
        }
    };

    const getGfrCalcText = (biomarkerName) =>
    {
        if (biomarkerName === "Estimated GFR (calc)")
        {
            return "(If patient is African American, multiply result by 1.210)";
        }
        else
        {
            return "";
        }
    }

    return (
        <div className="blood-insights-container">
            <h2>Blood Insights</h2>
                <div className="blood-insight-card">
                    <button className="card-button" onClick={prevInsight}>&lt;</button>
                    <div className="side-menu">
                        <ul>
                            <li>
                                <button className="results-button" onClick = {(ev) => fetchBioData(ev)}>See Your Results</button>
                            </li>
                            <li>
                                <img src={getGaugeImg(biomarkerInfo[currentIndex] && biomarkerInfo[currentIndex].resultInd)} className="gauge-img" alt="gauge-img"/>
                            </li>
                            <li>
                                <h3 className={getLevelStyleClass(biomarkerInfo[currentIndex] && biomarkerInfo[currentIndex].resultInd)}>
                                    {getLevel(biomarkerInfo[currentIndex] && biomarkerInfo[currentIndex].resultInd)}</h3>
                            </li>
                        </ul>
                    </div>
                    <div className="blood-insight-info">
                        <h2><b>{genericBloodInfo[currentIndex].name}</b></h2>
                        <h3><b>{getLevelKeyword(biomarkerInfo[currentIndex] && biomarkerInfo.length)}</b>
                            {biomarkerInfo[currentIndex] && biomarkerInfo[currentIndex].resultMgL}
                            {getLevelUnits(biomarkerInfo[currentIndex] && biomarkerInfo[currentIndex].testName)}</h3>
                        <p>{genericBloodInfo[currentIndex].description}</p>
                        <h3><b>Normal Ranges:</b></h3>
                        <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Male: </b>{genericBloodInfo[currentIndex].maleRange}</p>
                        <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Female: </b>{genericBloodInfo[currentIndex].femaleRange}</p>
                        <p>{getGfrCalcText(genericBloodInfo[currentIndex].name)}</p>
                        {/*<div className="full-insights">
                        <Link to ='/insights' className="more-insights">&#43; Full Insights</Link>
                        </div>*/}
                    </div>
                    <button className="card-button" onClick={nextInsight}>&gt;</button>
                </div>
       </div>
  );
};

