import React from 'react';
import axios from 'axios';
import '../style/widgets/supplementsWidget.css';
import logo from '../assets/logo-black.png';
import vitaminC from '../assets/vitaminc.png';
import greenTea from '../assets/greenTea.png';
import tumeric from '../assets/tumeric.png';
import vitaminD from '../assets/vitamind.png';
import iron from '../assets/Image=iron.png';
import b12 from '../assets/b12.png';
import magnesium from '../assets/Image=magnesium.png';
import omega3 from '../assets/Image=omega3.png';
import rhodiola from '../assets/Image=Rhodiola.png';
import chlorella from '../assets/Image=Chlorella.png';
import tudca from '../assets/Image=TUDCA.png';
import astaxanthin from '../assets/Image=zanthosyn.png';
import glucosamine from '../assets/Image=creatine.png';



export default class SupplementsListWidget extends React.Component
{
    state =
    {
        suppInfo: []
    }

    componentDidMount()
    {
        this.renderData();
    }

    renderData = async(suppInfo) =>
    {
        try {
            const res = await axios.get('https://macro-landing-442200-m2.wl.r.appspot.com/supplement/');
            if (res.data.length > 0)
            {
                this.setState({
                    suppInfo: res.data
                });
            }
            else
            {
                alert("Your supplement list did not load in the alloted time. Please refresh the page or logout and log back in to view your supplement list.");
            }
        } catch (err) {
            console.log(err);
        }
    }

    render()
    {

         const getSuppIndLifestyle = (sourceInd) =>
         {
             switch(sourceInd)
             {
               case 'L': return "source-green"
               case 'B': return "source-grey"
               case 'A': return "source-green"
               default: return "source-grey"
             }
         }

         const getSuppIndBlood = (sourceInd) =>
         {
             switch(sourceInd)
             {
               case 'L': return "source-grey"
               case 'B': return "source-green"
               case 'A': return "source-green"
               default: return "source-grey"
             }
         }

         const getSuppImg = (suppName) =>
         {
             switch(suppName)
             {
               case 'Iron': return iron
               case 'Vitamin C': return vitaminC
               case 'Vitamin D': return vitaminD
               case 'Vitamin B12': return b12
               case 'TUDCA': return tudca
               case 'Rhodiola': return rhodiola
               case 'Turmeric': return tumeric
               case 'Chlorella': return chlorella
               case 'Green Tea EGCG': return greenTea
               case 'Astaxanthin': return astaxanthin
               case 'Omega 3': return omega3
               case 'Glucosamine': return glucosamine
               case 'Magnesium': return magnesium
               case 'Omega 3 (Vegan)': return omega3
               case 'Turmeric with Black Pepper': return tumeric
               case 'Green Tea Extract EGCG': return greenTea
               default: return logo
             }
         }

         return (
             <div className="supplements-list-container">
                 <div className="supplements-list-headers">
                     <h2>My Supplements</h2>
                 </div>
                 <ul className="supplements-info">
                 {this.state.suppInfo?.map((supplement) =>
                    <li key={supplement.athleteSupplementId} className="supplement-item">
                        <img src={getSuppImg(supplement.suppName)} className="logo-img-small" alt="logo-img"/>
                        <span className="supplement-name">{supplement && supplement.suppName}</span>
                        <span className="supplement-dosage">{supplement && supplement.dosageKg}</span>
                        <div className={getSuppIndLifestyle(supplement.sourceInd)}>
                             <span>Lifestyle</span>
                        </div>
                        <div className={getSuppIndBlood(supplement.sourceInd)}>
                             <span>Blood Data</span>
                        </div>
                    </li>
                 )}
                 </ul>
             </div>
         )
    }
};
