import React from 'react';
/*import axios from 'axios';*/
import '../style/widgets/weeklyCheckIn.css';

export default function WeeklyCheckIn()
{

  /*
  const ONE_WEEK = 604800000; //one week in ms
  const [checkInTime, setCheckInTime] = useState(0);
  const [checkInSubmitted, setCheckInSubmitted] = useState(false);*/

  /*useEffect(() => {
      let data;
      axios.get('https://macro-landing-442200-m2.wl.r.appspot.com/weeklycheckin/').then(res => {
          data = res.data;
          if (data.length > 0 && data[0].checkinTime > 0)
          {
              if ((data[0].checkinTime + ONE_WEEK) >= Date.now())
              {
                  setCheckInSubmitted(true);
              }
              else
              {
                  setCheckInSubmitted(false);
              }
          }
          else
          {
              setCheckInSubmitted(false);
          }
          setCheckInTime(data);
      }).catch(err => {
          console.log(err.response);
      });

  }, []);

  if (!checkInTime) return <div>Loading...</div>;*/

  /*const handleCheckInSubmission = () => {
      setCheckInSubmitted(true);
      const params = new URLSearchParams();
      params.append('checkinTime', Date.now());

      axios({
          method: 'post',
          url: 'https://macro-landing-442200-m2.wl.r.appspot.com/weeklycheckin/',
          data: params
      }).then(res => {
          console.log("Returned from weekly checkin post")
      }).catch((err) => {
          console.log(err)
      });
  };*/
/*https://forms.gle/1kUhWyPRTY5DWEsS7*/
  return (
    <div className="weekly-check-in-container">
        <a
            href="https://forms.gle/7WUxPuigzFNGbwtg7"
            target="_blank"
            rel="noopener noreferrer"
            /*onClick={handleCheckInSubmission}*/
            className="check-in-link"
        >
        <button className="check-in-button">
          <span className="icon">↗️</span>
            Weekly Check-In
        </button>
        </a>
    </div>
  );
}
