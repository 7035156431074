import React from 'react';
import SideMenu from '../components/sideMenu';
import WeeklyCheckIn from '../components/weeklyCheckIn';
import SupplementsListWidget from '../components/supplementsWidget';
import BloodInsightsWidget from '../components/bloodWidget';
import GenomeInsightsWidget from '../components/genomeWidget';
/*import AthleteInfoWidget from '../components/athleteInfoWidget';*/
import '../style/dashboard.css';

export default function DashBoard()
{

    /*useEffect(() =>
    {
      let data;
      axios.get('https://macro-landing-442200-m2.wl.r.appspot.com/athlete/').then(res => {
          data = res.data;
          setUserName(data);
      }).catch(err => {
          console.log(err.response);
      })
    } ,[])*/

    return (
        <div className="dashboard-container">
          <SideMenu />
          <div className="main-content">
            <h3>Welcome to your OptiGenix Dashboard!</h3>
            <h1>Overview</h1>
            <div className="widget-content">
              <div className="left-content">
                <WeeklyCheckIn />
                <SupplementsListWidget />
              </div>
              <div className="right-content">
                <BloodInsightsWidget />
                <GenomeInsightsWidget />
              </div>
            </div>
          </div>
        </div>
    );
};
